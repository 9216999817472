<template>
  <div class="grid row">
    <div class="flex xs12 md12">
      <va-card title="Edit Admins">
        <div class="row">
          <va-input
            class="flex md9 xs12"
            v-model="email"
            placeholder="Admin Email"
          >
            <va-icon
              slot="prepend"
              color="gray"
              name="fa fa-envelope-o"
            />
          </va-input>
          <div class="flex md3 xs12 d-flex flex-center btn-container">
            <va-button
              @click="makeAdmin"
              v-if="!loading"
              :disabled="!email || email.length < 1"
            >
              <i
                class="va-icon iconicstroke iconicstroke-bolt"
                style="margin-right: 1em;"
              />  Make Admin
            </va-button>
            <va-button v-else>
              <semipolar-spinner
                :color="'white'"
              />
            </va-button>
          </div>
        </div>
        <div class="row">
            <va-data-table
             class="flex xs12 mb-2"
            :fields="fields"
            :loading="TableLoading"
            :data="filteredUsers"
            :per-page="perPage"
            @row-clicked="(row)=>{ gotoAdmin(row.uid) }"
            clickable
            hoverable
            >
              <template slot="profile" slot-scope="props">
                <va-item-section avatar>
                  <va-avatar>
                    <img
                      :src="props.rowData.photoURL"
                      :alt="props.rowData.displayName"
                    >
                  </va-avatar>
                </va-item-section>
              </template>
              <template slot="name" slot-scope="props">
                <va-item-section>
                  <va-item-label>
                    {{ props.rowData.displayName }}
                    <va-item-label caption>
                      {{ props.rowData.email }}
                    </va-item-label>
                  </va-item-label>
                </va-item-section>
              </template>
              <template slot="actions" slot-scope="props">
         
                    <va-button small @click="AuthModal(props.rowData)">
                      Change Permissions
                    </va-button>
        
              </template>
            </va-data-table>
        </div>

        <va-modal
          v-model="showPermissionsModal"
          hideDefaultActions
          class="flex xs12"
          title=""
          size="medium"
        > 
          <va-select
            v-model="selected"
            :options="options"
            label="Permissions Group"
            text-by = "text"
            key-by = "value"
          />
        <div class="pt-2">
              <va-button
                @click="changePermissions()"
                v-if="!loading"
              >
                <i
                  class="va-icon iconicstroke iconicstroke-bolt"
                  style="margin-right: 1em;"
                />  Update Permissions
            </va-button>
            <va-button v-else>
              <semipolar-spinner
                :color="'white'"
              />
            </va-button>
            <va-button
                slot="actions"
                @click="showPermissionsModal = false;"
                color="gray"
                class="mr-2"
            > Close </va-button>
          </div>

      </va-modal>


      </va-card>
    </div>
  </div>
</template>

<script>

import axios from '@/scripts/interceptor.js'
import {SemipolarSpinner} from 'epic-spinners';
import firebase from 'firebase';
export default {
  name: 'Admin',
  data() {
    return {
      email: '',
      message: 'f',
      fields: [{
        name: '__slot:profile',
        width: '30px',
      }, {
        name: '__slot:name',
        width: '30px',
      }, {
        name: '__slot:actions',
        dataClass: 'text-right',
      }],
      term: '',
      perPage: 10,
      perPageOptions: [10, 15, 20],
      showModal: true,
      loading: false,
      active: true,
      showPermissionsModal: false,
      authLoading: false,
      TableLoading: true,
      admins: [],
      selected: [],
      options: [
        { text: 'Admin Level I', value: '2' },
        { text: 'Admin Level II', value: '1' },
        { text: 'Admin Level III', value: '0' },
      ],
    };
  },
  components: {
    SemipolarSpinner,
  },
  computed: {
    filteredUsers () {
      if (!this.term || this.term.length < 1) {
        return this.admins
      }
      return this.admins.filter(user => {
        return user.firstName.toLowerCase().startsWith(this.term.toLowerCase())
      })
    },
  },
  methods: {
    ok() {
      this.showModal = false;
    },
    changePermissions(){
      this.authLoading = true;

      if(this.selected && this.currentRowData.uid){
        this.$swal.fire({
          title: 'Changing Permissions',
          text: 'Now changing permissions for ' + this.email + '...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          onOpen: async () => {
            this.$swal.showLoading();
            await axios.post(window.firebaseURL + `api/admin/updatePermissions`, {
                uid: this.currentRowData.uid, 
                role: this.selected
              }
            ).then((result) => {
              this.$swal.fire({
                  icon: 'success',
                  title: 'Success!',
                  text: 'Permissions have been updated for ' + this.currentRowData?.email + '!',
              }).then(async () => {
                    await this.refreshTable();
                    this.authLoading = false;
                    this.showPermissionsModal = false;
                    this.$swal.close();
              });
            })
            .catch((error) => {
                  this.$swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'Something went wrong while trying to fetch the admins! Please try again later.',
                  }).then(() => {
                    this.authLoading = false;
                    this.showPermissionsModal = false;
                    this.$swal.close();
                  });
            });
          },
        });
      }else{
        this.$swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong while trying to fetch the admins! Please try again later.',
        }).then(() => {
          this.loading = false;
          this.$swal.close();
        });
      }
    },
    AuthModal(data){
      this.active = false;
      this.currentRowData = data;
      if(!data.role){
        data.role = {
          value: '0',
          text: 'Admin Level III'
        }
      }
      this.selected = data.role;
      this.showPermissionsModal = true;
    },  
    validateEmail(email){
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    makeAdmin() {

      if(!this.validateEmail(this.email)){
        this.$swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please enter a valid email address!',
        });
        return;
      }

      this.loading = true;
      this.$swal.fire({
                  title: 'Granting Admin Access',
                  text: 'Now granting admin access to ' + this.email + '...',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: false,
                  onOpen: async () => {
                    this.$swal.showLoading();
                    await axios.post(window.firebaseURL + `api/admin/addAdminRole`, {
                      email: this.email,
                    }).then((result) => {
                      this.$swal.fire({
                          icon: 'success',
                          title: 'Success!',
                          text: result.data?.message,
                      }).then(async () => {
                            await this.refreshTable();
                            this.loading = false;
                            this.showPermissionsModal = false;
                            this.$swal.close();
                      });
                    })
                    .catch((error) => {
                          this.$swal.fire({
                              icon: 'error',
                              title: 'Oops...',
                              text: 'Something went wrong while trying to fetch the admins! Please try again later.',
                          }).then(() => {
                            this.loading = false;
                            this.$swal.close();
                          });
                    });
                  },
        })
    },
    gotoAdmin(uid) {
      if(this.active){
        this.$router.push({path: 'edituser/' + uid});
      }else{
        this.active = true;
      }
    },
    async refreshTable() {
      this.TableLoading = true;
      this.admins = [];

      //get from users collection users that have isAdmin true
      await firebase.firestore().collection('users').where('isAdmin', '==', true).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          //if photo.URL is null or undefined, set it to default
          let currentData = doc.data();
          //append key 'uid'
          currentData.uid = doc.id;
          if (!currentData.photoURL) {
            currentData.photoURL = 'https://nihss.plus/assets/defaultProfilePic.png'
          }
          this.admins.push(currentData);
          this.TableLoading = false;
        });
        //sort this.admins by displayName
        this.admins.sort((a, b) => {
          if (a.displayName < b.displayName) {
            return -1;
          }
          if (a.displayName > b.displayName) {
            return 1;
          }
          return 0;
        });
        this.loading = false;
      });
    },
  },
  
  async mounted() {
 
    await this.refreshTable();
  },
};
</script>

<style lang="scss">

  .btn-container button[disabled] {
    cursor: not-allowed;
  }

</style>
